.login-page{
    max-width: 700px;
    margin: 0;
    padding: 2rem;
}
.login-title{
font-size: 2rem;
font-weight: bold;
}
a:hover, a:visited, a:link, a:active
{
    text-decoration: none;
}
.sub-title, .form-label, .forgot-msg, .form-control{
    font-size: 1rem;
    /*font-weight: bold;*/
}
.invalid-user{
    font-weight: bold;
}
.form-group{
    margin-bottom: 2rem;
}
.login-page .sub-title {
    color: #373739;
    font-weight: 400;
    font-size: 1.2rem;
}
.form-group .form-control{
    border: 2px solid #737171;
    border-radius: 4px;
    font-weight: bold;
}
.login-page .form-group .form-label{
    color:#232735;
    font-weight: 500;
}
.forgot-msg{
    font-weight: 600;
    color: #232735;
}
.or-lebel {
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid #000; 
    line-height: 0.1em;
    margin: 10px 0 20px; 
 } 
 
 .or-lebel span { 
     background:#fff; 
     padding:0 10px; 
 }

 .err {
    font-size: small;
    color: red;
  }
  button:focus{
    color: #fff;
      background-color: #000;
      border-color: #343a40;
  }
 .form-group .toggle-eye{
    position: relative;
 }
 .toggle-eye .fa{
    position: absolute;
    font-size: large;
    color: #6c757d;
    margin-top: -45px;
    right: 15px;
 }
 .Spinner{
    position:fixed; 
    height:100%; 
    width:100%; 
   display: block;
   z-index: 9999;
   background-color: #0000002e;
 }
 .Spinner .Spinner-body{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
 }
 .login-page input{
    border-radius: 10px;
    padding: 1rem;
 }