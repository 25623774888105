:root {
  --primary: 132 90 223;
  --primary-rgb: 132, 90 ,223;
  --secondary: 35 183 229;
  --warning: 245 184 73;
  --info: 73 182 245;
  --success: 38 191 148;
  --danger: 230 83 60;
}
/* VolumeGauge.css */
.gauge-container {
  text-align: center;
  margin-top: 20px;
  width: 50%; /* Reduce size to 50% */
  margin-left: auto;
  margin-right: auto;
  font: size 100px; 
}

.range-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #333;
}

.interactive-message {
  margin-top: 15px;
  font-size: 14px;
  color: #4A4A4A;
  animation: fadeIn 1.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/** Cards style **/

.dashboard-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.header h1 {
  font-size: 36px;
  color: #785aff;
  text-align: center;
}

.header h2 {
  text-align: center;
  font-weight: 600;
}

.promoter-name {
  text-align: center;
  color: #785aff;
  margin-bottom: 20px;
}

.performance-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.gauge-card {
  flex: 1;
  min-width: 250px;
}

.stat-card .badge {
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.stat-card .badge.bg-purple {
  background-color: #785aff !important;
}
.stat-card .badge.bg-green {
  background-color: #1aa179 !important;
}  
.stat-value {
  font-size: 24px;
  margin: 5px 0;
}

.alert {
  color: red;
}

 .positive {
  color: green;
} 

.computation-section {
  margin-top: 20px;
}

.computation-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.computation-table td {
  padding: 8px;
  border: 1px solid #ccc;
}

.final-bonus td {
  font-weight: bold;
  background-color: #f4f4f4;
}

.negative{
  background: #e05a5a;
}
.netural{
  background: #7878c3;
}
.positive{
  background: #419141;
}

.table-heading{
  background: #0000ff87;
  padding: 13px;
  text-align: center;
}

.computation-section > div:not(:last-child){
  border-right: 4px solid;
  text-align: center;
}
.computation-section > div{
  text-align: center;
}

.stat-card > img{
  float:left;
}

.stat-diff{
  float:right
}

.computations table {
	border-color: rgb(210, 206, 206) !important;
}

.computations tbody tr td {
	padding: 0.5rem !important;
	border-color: rgb(210, 206, 206) !important;
}

.promoter-dashboard .card-header {
	background-color: #afd4fd;
	font-weight: 600;
	border-bottom-color: rgb(243 243 243);
	padding: 0.5rem 1rem;
}

.promoter-dashboard .card-header::before {
	position: absolute;
	top: 0.15rem;
	inset-inline-start: -0.65rem;
	height: 1rem;
	width: 0.2rem;
	border-radius: 0.5rem;
	background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
	--tw-gradient-from: rgb(var(--primary) / 0.5) var(--tw-gradient-from-position);
	--tw-gradient-to: rgb(var(--primary) / 0) var(--tw-gradient-to-position);
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
	content: "";
	--tw-gradient-to: rgb(var(--secondary) / 0.5) var(--tw-gradient-to-position);
}

.promoter-dashboard .computations .bg-info {
  color: rgb(var(--info));
  background-color: rgb(var(--info) / 0.1) !important;
}
.promoter-dashboard .computations .bg-secondary {
  color: rgb(var(--secondary));
  background-color: rgb(var(--secondary) / 0.1) !important;
}
.promoter-dashboard .computations .bg-success {
  color: rgb(var(--success));
  background-color: rgb(var(--success) / 0.1) !important;
}
.promoter-dashboard .computations .bg-danger {
  color: rgb(var(--danger));
  background-color: rgb(var(--danger) / 0.1) !important;
}
.promoter-dashboard .computations .bg-warning {
  color: rgb(var(--warning));
  background-color: rgb(var(--warning) / 0.1) !important;
}

.promoter-dashboard .stats-cards .card.bg-success {
	background-color: rgba(92, 225, 123, 0.6) !important;
}

.promoter-dashboard .stats-cards .card.bg-danger {
	background-color: rgba(255, 130, 111, 0.6) !important;
}

.promoter-dashboard .stats-cards .card.bg-purple {
	background-color: #b9aaff99 !important;
}
.gauge-value h4 {
  font-size: 20px;
 }

.gauge-value h6 {
  font-size: 20px; 
}
@media (min-width: 1045px) and (max-width: 1200px) {
  .gauge-container {
    width: 60%;
  }
}


@media (min-width: 768px) and (max-width: 992px) {
  .gauge-value h4 {
    font-size: 18px;
    max-width: 150px; 
  }
 
  .gauge-container {
    width: 70%;
  }
}

@media (min-width: 400px) and (max-width: 640px) {
  .gauge-value h4 {
    max-width: 150px; 
  }
}

@media (max-width: 399px) {
  .gauge-value h4 {
    max-width: 120px; 
    font-size: 17px;
  }
}