.page-title,
.title {
    font-size: 2rem;
    font-weight: bold;
}

.page-title-mv,
.title-mv {
    font-size: 1rem;
    font-weight: bold;
}

/**
.payment-checks ,.items-list{
    padding-top: 3rem;
}
**/
.centered-link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(120, 90, 255);
}

.payment-tile {
    border: 1px solid #f1f1f1;
    /**  margin-bottom: 3rem; **/
    border-radius: 10px;
    background-color: #FFF;
    margin: 0.5rem;
}

.tenure-section .payment-tile {
    border-width: 3px;
}

.items-list .container {
    max-width: 100%;
}

.amount {
    font-weight: bold;
    font-size: 2rem;
}

.emi-amount {
    font-weight: bold;
    font-size: 1.5rem;
}

.emi-amount-mv {
    font-weight: bold;
    font-size: 1rem;
}

.description,
.sub-title {
    /*font-weight: bold;*/
    font-size: 1rem;
    color: #737171;
}

.description-mv,
.sub-title {
    /*font-weight: bold;*/
    font-size: 0.7rem;
    color: #737171;
}

.item-details {
    padding-top: 3rem;
    margin-bottom: 3rem;
}

.hidden-text {
    visibility: hidden;
    /* The text is invisible, but space is reserved */
}

.container-mv {
    display: grid;
    grid-template-columns: 46% 8% 46%;
    /* Three width columns */
    /* grid-gap: 10px; Adjust the gap between columns */
}

.item {
    border: 1px solid #f1f1f1;
    padding: 1rem 2rem;
    border-radius: 10px;
}

.item select,
.item input {
    border: 1px solid #000;
    border-radius: 0;
}

.item .row {
    margin-bottom: 2rem;
}

.item-add {
    border: 1px dashed #f1f1f1;
    padding: 6rem;
    border-radius: 10px;
}

.Add-btn {
    font-weight: bold;
}

.tenure {
    font-weight: bold;
    font-size: 1rem;
    color: #000;
    margin-bottom: 2rem;
}

.row-flex {
    display: flex;
    flex-wrap: wrap;
}

.tonik-color {
    color: rgb(120, 90, 255);
}

a,
a:hover,
a:focus {
    color: rgb(120, 90, 255);
    text-decoration: none;
}

.add-player-div {
    height: 100%;
    padding: 20px 20px 10px;
    color: #fff;
    background-color: #fff;
    border-radius: 16px;
}

.loan_EMI_Details {
    border-radius: 10px;
}

#apply-now[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 50%;
    width: 100%;
    background-color: #fff;
    border: 1px;
    border-radius: 0.5rem;
    outline: 0;
}

.btn-apply {
    --bs-btn-color: rgb(222, 222, 222);
    --bs-btn-bg: #0d6efd;
    --bs-btn-border-color: #0d6efd;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #0a58ca;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd;
}

.divBorder {
    border: 1px solid #f1f1f1;
}

/* Style for the border line div */
.border-line {
    border-bottom: 1px solid #ccc;
    /* Defines a 1px solid border line at the bottom */
}


/* Style for the container div (Optional, for demonstration purposes) */
.containerDiv {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f0f0f0;
}

#down-pay-amount {
    border: none;
    border-bottom: 2px solid var(--primary-color);
    border-radius: 0px;
}

body .btn-purple,
.btn-purple:focus {
    background-color: var(--primary-color);
    color: #fff;
    font-weight: 600;
    font-size: 13px;
    border-radius: 12px;
    padding: 8px 16px;
    line-height: 1.1;
    border: 2px solid transparent;
}

body .btn.btn-purple:hover,
.btn.btn-purple:focus-visible {
    background-color: var(--bs-btn-hover-color);
    border-color: var(--primary-color);
    color: var(--primary-color);
    border-width: 2px;
}

.rate-chart {
    text-align: center;
    color: rgba(55, 55, 57, 1);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.048px;
    padding: 15px;
}

.addon-rate {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.418px;
    color: #000000;
    padding-bottom: 7px;
}

.addon-rate.selected-item {
    font-size: 32px;
    color: #785AFF;
}

.addon-monthly {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.165px;
    color: rgba(113, 113, 117, 1);
}

.cta-wrap {
    display: flex;
    gap: 10px;
}

.compare-modal .modal-dialog {
    margin: 0 auto;
}

.compare-modal .modal-content {
    margin: 0;
    border-radius: 0;
}

.compare-modal .head h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -0.022em;
    text-align: center;
}

.compare-modal .head h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
}

.slot-compare {
    background-color: #F0FAFA;
    border: 3px solid #C9ECFF;
    border-radius: 15px;
    padding: 12px;
    gap: 8px;
    display: flex;
    flex-direction: column;
}

.price-slot {
    border: 1px solid #F0F1F3;
    padding: 12px;
    background-color: #fff;
    border-radius: 16px;
}

.compare-modal .flex-wrap {
    display: flex;
    gap: 4px;
    justify-content: center;
}

.compare-modal .flex-wrap>div {
    width: calc(50% - 2px);
}

.compare-modal h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0;
}

.compare-modal h3~p {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
}

.tg-name {
    margin: 0;
    color: #717175;
    font-size: 15px;
    font-weight: 700;
}

.price-slot h4,
.price-slot .emi-amount span {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.7;
    text-align: left;
    margin-bottom: 0px;
}

.tenure-slot {
    cursor: pointer;
    user-select: none;
    padding: 0px;
}

.tenure-slot.selected-tenure .payment-tile,
.tenure-slot[data-select="selected-tenure"] .payment-tile {
    border-color: var(--primary-color);
}

.tenure-slot .payment-tile {
    border: 3px solid transparent;
}

.tenure-slot .payment-tile {
    margin: 0;
    padding: 12px;
}

.tenure-slot .tenure {
    margin: 0;
}

.tenure-slot .tenure span {
    display: block;
    font-weight: 600;
    color: #232735;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
}

.tenure-section>.row>.flex-column {
    gap: 12px;
}

.close-compare-modal {
    display: block;
    cursor: pointer;
    margin-bottom: 30px;
}

.compare-modal .modal-body {
    padding: 5px;
}

.compare-modal .head {
    padding: 10px;
}

.compare-modal .btn-purple {
    margin-top: 11px;
}

.payment-checks {
    margin: 24px 0 32px;
}

.uppercase-input {
    text-transform: uppercase;
}

.payHingaTag {
    color: var(--primary-color);
}

.nonVas .payHingaTag {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
}

.slot-compare .payHingaTag {
    display: none;
}

.downpayment-modal .h4 {
    font-weight: 600;
}

.downpayment-bd.modal-backdrop {
    z-index: 1055;
}

.downpayment-modal button.btn-close:focus {
    background-color: inherit;
}

/* SMS SCREEN */
.Next-btn .next-page-btn>button i {
    margin-left: 10px;
}

.Next-btn .next-page-btn {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.btn.btn-dark {
    background-color: #121232;
    width: 200px;
    border-radius: 4px;
}

/* SIL ZERO */

.sil-zero-switch .content p {
	color: #232735;
	font-size: 19px;
	font-weight: 700;
}

.sil-zero-switch .form-switch {
    padding: 0;
}

.sil-zero-switch .form-switch .form-check-input {
	cursor: pointer;
	height: 32px;
	width: 51px;
	background-size: 35px;
    margin: 0;
}

.sil-zero-switch .form-check-input:checked {
	background-color: rgb(120, 90, 255);
	border-color: rgb(120, 90, 255);
	background-position: 16px center !important;
}

.sil-zero-switch .form-check-input:focus {
    box-shadow: unset;
}

.sil-zero-proccessfee {
    color: #717175;
    font-size: 19px;
    font-weight: 600;
    margin: 0;
    line-height: 24px
}

.compare-modal .sil-zero-proccessfee {
	line-height: 1;
	margin: 0;
}


@media only screen and (max-width: 767px) {

    .items-list,
    .payment-checks {
        padding-top: 0rem;
    }

    .sub-title {
        margin-bottom: 0rem;
    }

    .main-body .tenure-section {
        display: grid;
        grid-template-columns: 46% 8% 46%;
    }

    .main-body .tenure-section>.row>.d-flex,
    .loan-summary.flex-row {
        flex-direction: column !important;
    }

    .main-body .payment-checks {
        overflow: initial !important;
    }

    .dots>div {
        height: 100%;
        pointer-events: none;
    }

    .centered-link {
        transform: rotate(90deg);
        height: 100%;
    }

    .Next-btn .next-page-btn {
        align-items: center;
        flex-direction: column;
    }

}

@media only screen and (max-width: 468px) {

    .page-title,
    .title {
        font-size: 1.5rem;
    }

    .sub-title,
    .form-label,
    .forgot-msg,
    .form-control {
        font-size: 1rem;
    }

    .add-player-div.p-2 {
        padding: 1rem !important;
    }

    .add-player-div h4 {
        font-size: 1.2rem;
        padding: 0rem 0 0.5rem !important;
    }

    .items-list .container .col-md-4 {
        padding: 0.5rem 0 !important;
    }

    .emi-amount,
    .amount {
        font-weight: bold;
        font-size: 1rem;
    }

    .description,
    .sub-title {
        font-size: 0.7rem;
    }

    .addon-rate {
        font-size: 1.2rem;
    }

    .addon-rate.selected-item {
        font-size: 1.5rem;
    }

    .addon-monthly {
        font-size: 13px;
    }
}

@media only screen and (max-width: 400px) {
    .down-payment .btn-purple {
        font-size: 10px;
        border-radius: 6px;
        padding: 4px 8px;
    }

    .slot-compare {
        padding: 6px;
    }

    .tg-name {
        font-size: 13px;
    }

    .compare-modal h3 {
        font-size: 20px;
    }

    .price-slot h4,
    .price-slot .emi-amount span {
        font-size: 18px;
    }

    .tenure-slot span {
        font-size: 14px;
    }

    .main-body .loan_EMI_Details {
        padding: 1rem !important;
    }
}

@media only screen and (max-width: 420px) {
    .payHingaTag {
        font-size: 11px;
    }

    .tenure {
        font-size: 15px;
    }
}

.application-type-container {
    margin: 20px 0;
}

.application-type-container .css-tj5bde-Svg {
    color: rgb(18, 16, 16);
}

.application-type-container .css-13cymwt-control {
	cursor: pointer;
	border-color: #A1A4B1;
	height: 48px;
}

.application-type-container .css-t3ipsp-control {
	cursor: pointer;
	border-color: #A1A4B1;
    box-shadow: #A1A4B1;
	height: 48px;
}

.application-type-container .css-t3ipsp-control:hover {
	border-color: #A1A4B1;
    box-shadow: unset;
}

.application-type-container .page-title {
    margin-bottom: 20px;
}

.application-type-container .css-1nmdiq5-menu {
    cursor: pointer !important;
}