.QR-code-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    padding: 3rem;
    text-align: center;
}

.QR-Desc {
    padding: 1.5rem;
}

.QR-Desc .Scan-QR {
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    font-weight: 600;
    color: #785AFF !important;
}

.QR-Desc .sub-Scan-QR {
    text-align: center;
    color: #785AFF !important;
    font-size: 14px;
    font-weight: 400;
}

.QR_product_info {
    background-color: rgb(255, 255, 255);
    border: 1px solid #f1f1f1;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Product-details {
    text-align: center;
}

.QR_info .col {
    text-align: left;
}

.QR_info .row {
    padding: 1rem;
    border-bottom: 1px solid #f1f1f1;
}

.QR_info .row:last-child {
    padding: 1rem;
    border-bottom: 0;
}

table td {
    text-align: left;

    padding: 1rem;
}

table tr {
    border-bottom: 1px solid #f1f1f1;
}

tbody tr td {
    padding: 1rem !important;
}

table {
    margin-bottom: 0;
}

.row-flex {
    display: flex;
    flex-wrap: wrap;
}

.row-flex>[class*='col-'] {
    display: flex;
    flex-direction: column;
}

.row-flex>[class='col-md-8'] {
    border: 1px solid #f1f1f1;
    border-radius: 10px;
}

.QR_title {
    font-size: 1.5rem;
    font-weight: bold;
}

.QR_product_info label {
    font-weight: 600;
    color: #8B90A0;
}

.QR_code_space {
    border: 2px solid #000;
    padding: 1rem;
    border-radius: 10px;
}

@media only screen and (max-width: 767px) {
    .QR-code-block {
        border: 1px solid #f1f1f1;
        border-radius: 10px;
        padding: 0rem;
        text-align: center;
    }
}

.QR-Code-Genarated {
    padding-top: 3rem;
}

/* SMS FLOW */

.item-details {
    padding-top: 13px;
}

.shoping-details {
    margin-top: 60px;
}

.QR-Code-Page .shoping-details {
    margin-top: 40px;
}

.QR_product_info.qr_block {
    padding: 48px;
}

.qr_block h5 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 18px;
}

.sub-Scan-QR {
    margin-top: 5px;
}

.sub-Scan-QR p {
    margin: 0;
}

.SMS_form {
    background-color: rgb(255, 255, 255);
    border: 1px solid #f1f1f1;
    border-radius: 24px;
    padding: 48px 24px;
}

.SMS_form .form-group input.form-control[type="text"] {
    border: unset;
    border-bottom: 2px solid #EAEAEB;
    border-radius: 0;
    font-weight: 500;
    color: #373739;
    padding: 0 0 7px 0;
    margin-bottom: 0 !important;
}

.SMS_form .form-group .form-label {
    font-size: 14px;
    font-weight: 600;
    color: #717175;
    margin: 0;
}

.SMS_form .form-group .form-control:focus,
.sendSms button.form-control:focus {
    box-shadow: unset;
}

.SMS_form button.form-control,
.sendSms button.form-control {
    border: unset;
    padding: 12px 16px;
    font-size: 14px;
    line-height: 16px;
}

.SMS_form button.form-control:focus,
.sendSms button.form-control:focus {
    background-color: #121232;
    color: #fff;
}

.SMS_form button.form-control i {
    margin-left: 8px;
}

.error-text {
    color: #FA8585;
    font-size: 11px;
    line-height: 1;
    font-weight: 600;
    display: block;
    margin-top: 7px;
}

/* POPUP */

.sendSms .modal-title {
    font-size: 22px;
    font-weight: 600;
}

.sendSms .modal-header {
    border-bottom: unset;
}

.sendSms .content {
    font-size: 16px;
    font-weight: 400;
    color: #717175;
    word-wrap: anywhere;
}

.iconWrapper {
    background-color: #CDC2FF;
    border-radius: 100%;
    display: flex;
    padding: .75rem;
}

.iconWrapper .icon {
    color: var(--tdb-primary-color);
}

.successMessage i,
.errorMessage i {
    background-color: var(--tdb-primary-color);
    border-radius: 100%;
    color: #fff;
    padding: 6px;
    font-size: 13px;
}

.successMessage .icon path,
.errorMessage .icon path {
    display: none;
}

.errorMessage .iconWrapper {
    background-color: #FDC4C4;
}

.errorMessage i {
    background-color: #FA8585;
}

.sendSms.successMessage .modal-title {
	max-width: 150px;
	margin-bottom: 40px;
	line-height: 1.2;
}

.sendSms .modal-content {
    margin-top: 0 !important;
}

@media only screen and (max-width: 767px) {
    .QR-Desc {
        padding: 1.5rem 0;
    }
    .QR-Code-Page .shoping-details {
        margin-top: 0;
    }
}