.side-nav {
  display: block;
  border-right: 2px solid #f8f9fa;
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  padding: 3rem 0;
  text-align: center;
  min-height: 100vh;
}

.main-body {
  margin-left: 250px;
  width: 100%;
  padding: 3rem 1rem;
  overflow-x: auto;

}

.profile-pic {
  border-radius: 50%;
}

.profile-pic .profile-photo {
  width: 100%;
}

.user-name {
  font-weight: bold;
}

.profile-photo img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #000;
  text-align: center;
}

.navs {
  text-align: center;
}

.navs ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding-left: 0;
}

.navs ul li {
  padding: 1rem;
  font-size: 1rem;
  color: #c8c7c7;
  font-weight: 500;
}

.toggle-btn {
  display: none;
}

.side-nav-bar {
  display: block;
}

.popup-content {
  font-size: 16px; /* Adjust font size for the content */
  max-width: 100%; /* Ensure the content doesn't exceed modal width */
  margin: -9% auto; /* Center the content horizontally */
  padding: 20px; /* Add padding for spacing */
    /* Apply 50% scale to the content 
     transform: scale(0.5);*/
}

@media only screen and (max-width: 767px) {
  .popup-content {
    font-size: 14px; /* Adjust font size for smaller screens */
    padding: 10px; /* Reduce padding for smaller spacing */
  }

  .main-body {
    width: 100%;
    padding: 3rem 1rem;
    margin-left: 0px;
  }

  .toggle-btn {
    display: block;
    position: fixed;
    z-index: 999;
    left: 10;
    top: 10px
  }

  .side-nav {
    width: 100%;
  }
}

.qr {
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.qr::before,
.qr::after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
}

.qr::before {
  background-color: #54b3d6;
  height: 2px;
  bottom: 0;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}

.qr::after {
  content: attr(data-replace);
  height: 100%;
  top: 0;
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
  color: #54b3d6;
}

.qr:hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}

.qr:hover::after {
  transform: translate3d(0, 0, 0);
}

qr.span {
  display: inline-block;
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}

.qr:hover span {
  transform: translate3d(-50%, 0, 0);
}



.qc {
  text-decoration: none;
  color: #505565;
  font-weight: 500;
  vertical-align: top;

}

.lo {
  text-decoration: none;
  color: #8B90A0;
  font-weight: 500;
  vertical-align: top;
}

@media only screen and (min-width: 767px) {
  .side-nav-bar{
      display: block !important;
    }
}