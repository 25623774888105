:root {
  --primary-color : #785aff;
}
body{
  overflow-x: hidden;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.uppercase-input{
  text-transform: uppercase;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
  .uppercase-input{
    text-transform: uppercase;
  }
}
.valid{
  color: rgb(30, 239, 30)
}
.valid,.invalid{
  font-size: 1rem;
  font-weight: bold;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.text-color{
  color: #785AFF;
}
@-moz-keyframesy spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(359deg);
  }
  }
  .fa {
      animation: 2s linear 0s normal none infinite spin;
  }