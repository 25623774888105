.checkbox-custom {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #785aff; /* Set the background color */
  border: 1px solid #999;
  border-radius: 50%;
}

/* Style the tick mark */
.checkbox-custom::before {
  content: "";
  display: block;
  position: absolute;
  width: 5px;
  height: 10px;
  background-color: #785aff;
  border: solid white; /* Set the color of the tick mark */
  border-width: 0 2px 2px 0;
  top: 50%;
  left: 30%;
  transform: rotate(45deg) translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s;
}

/* Show the tick mark when the checkbox is checked */
.checkbox-custom:checked::before {
  opacity: 1;
}
